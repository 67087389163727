export default class queue{
    constructor(length = 0){
        this.length = length;
        this.arr = [];
    }

    setLength(length){
        this.length = length;
    }
    push(params) {
        if(this.arr.length >= this.length){
            this.pop();
        }
        this.arr.push(params)
    }
    pop(){
        this.arr.shift();
    }
    get(){
        return this.arr;
    }
    clear(){
        this.arr = [];
    }
    getMax(){
        let max = this.arr[0];
        this.arr.forEach(e => {
            if(Number(e) >Number(max)){
                max = e
            }
        })
        return max
    }
    getMin(){
        let min = this.arr[0];
        this.arr.forEach(e => {
            if(Number(e)<Number(min)){
                min = e
            }
        })
        return min
    }
}