function getPing() {
  return {
    ping: new Date().getTime(),
  };
}
const interval = 5 * 1000;
let timerid = null;
function pingPong(ws) {
  if (timerid) {
    clearInterval(timerid);
  }
  timerid = setInterval(() => {
    if (ws.readyState === 1) {
      const ping = JSON.stringify(getPing());
      ws.send(ping);
    } else {
      clearInterval(timerid);
    }
  }, interval);
}
const refreshInterval = 1000;
class socket {
  constructor(url = 'wss://api.fcoin.com/v2/ws', options) {
    this.heartBeatTimer = null;
    this.options = options;
    this.messageMap = {};
    this.connState = 0;
    this.socket = null;
    this.url = url;
    this.subList = [];
    this.openFunc = [];
    this.closeFunc = [];
    this.dataList = [];
    this.init();
  }

  init() {
    this.lock = true;
    this.openDone = false;
    this.reconnectLeft = 0;
    this.doOpen();
  }

  doOpen() {
    if (this.connState) return;
    this.connState = 1;
    this.afterOpenEmit = [];
    const BrowserWebSocket = window.WebSocket || window.MozWebSocket;
    const socket = new BrowserWebSocket(this.url);
    socket.binaryType = 'arraybuffer';
    socket.onopen = (evt) => this.onOpen(evt);
    socket.onclose = (evt) => this.onClose(evt);
    socket.onmessage = (evt) => this.onMessage(evt.data);
    socket.onerror = (err) => this.onError(err);
    this.socket = socket;
  }

  onOpen(evt) {
    console.log(`${this.url}连接开启1.....`);

    this.reconnectLeft = 0;
    this.checkPingPong();
    if (this.openFunc.length > 0) {
      Promise.all(this.openFunc.map((fn) => fn())).then(() => {
        this.openDone = true;
        if (this.dataList.length > 0) {
          this.dataList.forEach((data) => {
            this.subList.forEach((func) => func(data));
          });
        }
      });
    } else {
      this.openDone = true;
    }
  }

  checkOpen() {
    return this.connState === 2;
  }

  onClose(cws) {
    console.log(new Date().getTime(), 'socket断开连接', cws);
    if (this.checkPingPongTimer) {
      clearInterval(this.checkPingPongTimer);
    }
    console.log(this.lock);
    if (this.lock) {
      this.closeFunc.forEach((cb) => cb());
      const timeOut = setTimeout(() => {
        console.log(new Date().getTime(), 'socket重连');
        if (this.connState !== 0) this.connState = 0;
        this.doOpen();
        clearTimeout(timeOut);
      }, refreshInterval);
    }
  }

  send(data) {
    this.socket.send(JSON.stringify(data));
  }

  emit(data) {
    return new Promise((resolve) => {
      this.socket.send(JSON.stringify(data));
      this.on('message', (data) => {
        resolve(data);
      });
    });
  }

  onMessage(message) {
    try {
      const data = JSON.parse(message);
      const newData = {
        time: parseInt(data.sT) || parseInt(data.V),
        bid: data.b,
        offer: data.a,
        open: data.o,
        high: data.h,
        low: data.l,
        close: data.c,
        symbol: data.S,
        volume: data.v,
        time: data.sT,
        timestamp: parseInt(data.eT) || parseInt(data.T)
        
      };
      this.onReceiver({ Event: 'message', Data: newData });
      // if (data.T) {
      //   this.pong();
      // }
      // if (this.openDone) {
      //   this.subList.forEach(func => func(newData));
      // } else {
      //   this.dataList.push(newData);
      // }
    } catch (err) {
      console.log(err);
    }

    pingPong(this.socket);
  }

  checkHeartbeat() {
    const data = {
      cmd: 'ping',
      args: [Date.parse(new Date())],
    };
    this.send(data);
  }

  onError(err) {
    console.log('err:', err);
  }

  onReceiver(data) {
    const callback = this.messageMap[data.Event];
    if (callback) callback(data.Data);
  }

  on(name, handler) {
    this.messageMap[name] = handler;
  }

  doClose() {
    this.socket.close();
  }

  destroy() {
    if (this.heartBeatTimer) {
      clearInterval(this.heartBeatTimer);
      this.heartBeatTimer = null;
    }
    this.doClose();
    this.messageMap = {};
    this.lock = true;
    this.connState = 0;
    this.socket = null;
  }

  checkPingPong() {
    this.checkPingPongTimer = setInterval(() => {
      this.reconnectLeft += 1;
      if (this.reconnectLeft > 100000 * 100000) {
        // 重连100000次后断开
        if (this.socket.readyState === 1 || this.socket.readyState === 0) {
          this.socket.close();
        } else {
          this.connState = 0;
          this.doOpen();
        }
        this.reconnectLeft = 0;
        clearInterval(this.checkPingPongTimer);
      }
    }, interval);
  }

  pong() {
    this.reconnectLeft = 0;
  }

  addSubFunc(func) {
    this.subList.push(func);
  }

  addCloseFunc(func) {
    this.closeFunc.push(func);
  }

  addOpenFunc(func) {
    this.openFunc.push(func);
  }

  close() {
    this.lock = false;
    this.socket.close();
  }
}
export default socket;
